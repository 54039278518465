// example theme file
// example base theme from @theme-ui/presets
const theme = {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "Domine",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#161616", //off black
    background: "#faf8f1",
    primary: "#13392B", //title green
    secondary: "#bfa151", //mustard
    secondaryLighter: "#c3a760", //lighter mustard
    brand: "#215766", //brand logo green colour
    muted: "#b2b2b2", //grey
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
  buttons: {
    primary: {
      color: "secondary",
      border: theme => `2px solid ${theme.colors.secondary}`,
      borderRadius: "10px",
      bg: "transparent",
      px: 2,
      py: 1,
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: 1,
      cursor: "pointer",
      "&": {
        transition: "all 0.3s ease",
      },

      "&:hover, &:focus-within": {
        bg: "secondary",
        color: "white",
      },
    },
    secondary: {
      color: "white",
      border: theme => `2px solid ${theme.colors.secondary}`,
      borderRadius: "10px",
      bg: "secondary",
      px: 2,
      py: 1,
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: 2,
      cursor: "pointer",
      "&": {
        transition: "all 0.3s ease",
      },

      "&:hover, &:focus-within": {
        bg: "text",
        color: "white",
        border: theme => `2px solid ${theme.colors.text}`,
      },
    },
    primaryGreen: {
      color: "brand",
      border: theme => `2px solid ${theme.colors.brand}`,
      borderRadius: "10px",
      bg: "transparent",
      px: 2,
      py: 1,
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: 1,
      cursor: "pointer",
      "&": {
        transition: "all 0.3s ease",
      },

      "&:hover, &:focus-within": {
        bg: "brand",
        color: "white",
      },
    },
  },
  styles: {
    //root instead of global styles
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",

      h1: {
        variant: "text.heading",
        fontSize: 5,
      },
      h2: {
        variant: "text.heading",
        fontSize: 4,
      },
      h3: {
        variant: "text.heading",
        fontSize: 3,
      },
      h4: {
        variant: "text.heading",
        fontSize: 2,
      },
      h5: {
        variant: "text.heading",
        fontSize: 1,
      },
      h6: {
        variant: "text.heading",
        fontSize: 0,
      },
    },
    h1: {
      variant: "text.heading",
      fontSize: 5,
    },
    h2: {
      variant: "text.heading",
      fontSize: 4,
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}

export default theme
